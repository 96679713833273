<template>
    <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('audio.add') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <AudioForm :media="{}" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue";
import AudioForm from "@/components/admin/audio/AudioForm.vue";

export default {
    name: "AddAudio",
    components: { NavBar, AudioForm }    
}
</script>

<style></style>
