<template>
	<div>
		<b-form @submit.prevent="onSubmit" autocomplete="off">
			<b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('general.title')" label-for="title">
			<!-- Naslov je dosta specifičan. Ako je u pitanju latinično pismo na srpskom, onda se pored naslova -->
			<!-- trebalo da se pojavi ikonica za LAT tag. -->
			<template v-if="$root.lang.IANA === 'sr-Latn' || $root.lang.IANA === 'sr-Cyrl'">
				<b-row>
					<b-col md="11">
						<b-form-input	id="title" :state="titleState" v-model="media.title" :placeholder="$t('general.enterTitle')" autocomplete="new-password" trim></b-form-input>
						<b-form-invalid-feedback>{{	$t("general.enter-valid-title")	}}</b-form-invalid-feedback>
					</b-col>
					<b-col md="1" class="col-form-label">
						<span id="latIcon" @click="insertLat('title')">LAT</span>
					</b-col>
				</b-row>
			</template>
			<template v-else>
				<b-row>
					<b-col md="12">
						<b-form-input	id="title" :state="titleState" v-model="media.title" :placeholder="$t('videos.enter-title')" autocomplete="new-password" trim></b-form-input>
						<b-form-invalid-feedback>{{	$t("general.enter-valid-title")	}}</b-form-invalid-feedback>
					</b-col>
				</b-row>
			</template>
			</b-form-group>			
			
			<b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('audio.file')">
				<BrowseButton :state="mediaFileState" id="audioFile" name="mediaFile"	:buttonText="$t('audio.choose')" model="media.file"	:noFile="$t('audio.no-audio-file-selected')" />
				<b-form-invalid-feedback :state="mediaFileState">{{	$t("audio.select-valid-audio-file")	}}</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group	label-cols-sm="12"	label-cols-md="3"	label-cols-lg="4"	:label="$t('general.description')"	label-for="description">
				<editor	id="description"	:state="descriptionState"	:init="tinyInitAudioDesc"	v-model="media.description"></editor>
				<b-form-invalid-feedback :state="descriptionState">{{	$t("general.enterDescription") }}</b-form-invalid-feedback>
				<small v-if="$root.lang.IANA === 'sr-Latn'"
					>Napomena: ukoliko unosite reči na stranim jezicima (latiničnim)
					označite ih i kliknite ikonicu LAT, kako bi se ispravno prikazale na
					ćiriličnoj verziji.</small>
			</b-form-group>

			<b-form-group	label-cols-sm="12"	label-cols-md="3"	label-cols-lg="4"	:label="$t('general.titleEn')"	label-for="title">
				<b-form-input	id="titleEn" v-model="media.titleEn" :state="titleEnState" :placeholder="$t('general.enterTitleEn')"	autocomplete="new-password"	trim></b-form-input>
				<b-form-invalid-feedback :state="titleEnState">{{
					$t("general.enterTitleEnError")
				}}</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group
				label-cols-sm="12"
				label-cols-md="3"
				label-cols-lg="4"
				:label="$t('general.descriptionEn')"
				label-for="descriptionEn"
			>
				<editor
					id="descriptionEn"
					:state="descriptionEnState"
					:init="tinyInitAudioEditDescEn"
					v-model="media.descriptionEn"
				></editor>
				<b-form-invalid-feedback :state="descriptionEnState">{{
					$t("general.descriptionEnError")
				}}</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group
				label-cols-sm="12"
				label-cols-md="3"
				label-cols-lg="4"
				:label="$t('general.author')"
				label-for="author"
			>
				<b-form-input
					id="author"
					:state="authorState"
					v-model="media.author"
					:placeholder="$t('general.enter-author')"
					trim
				></b-form-input>
				<b-form-invalid-feedback>{{
					$t("general.enter-valid-author")
				}}</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group
				label-cols-sm="12"
				label-cols-md="3"
				label-cols-lg="4"
				:label="$t('audio.year')"
				label-for="year"
			>
				<b-form-input
					id="year"
					:state="yearState"
					v-model="media.year"
					:placeholder="$t('audio.enter-year')"
					trim
				></b-form-input>
				<b-form-invalid-feedback>{{
					$t("general.enter-valid-year")
				}}</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group
				label-cols-sm="12"
				label-cols-md="3"
				label-cols-lg="4"
				:label="$t('general.languages')"
			>
				<TagInput
					id="langauges"
					:state="languagesState"
					:model="selectedLangs"
					:options="avaiableLanguageList"
					:type="$t('general.language')"
					@input="selectedLangs = $event"
					:error="$t('general.please-select-language')"
				/>
			</b-form-group>

			<b-form-group
				label-cols-sm="12"
				label-cols-md="3"
				label-cols-lg="4"
				:label="$t('general.categories')"
			>
				<TagInput
					id="categories"
					:state="categoriesState"
					:model="selectedCats"
					:options="avaiableCategoryList"
					:type="$t('general.category')"
					@input="selectedCats = $event"
					:error="$t('general.please-select-categories')"
				/>
			</b-form-group>

			<b-form-group
				label-cols-sm="12"
				label-cols-md="3"
				label-cols-lg="4"
				:label="$t('general.published')"
			>
				<b-form-checkbox v-model="media.published" switch></b-form-checkbox>
			</b-form-group>

			<b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4">
				<b-form-checkbox
					:state="acceptedTermsState"
					id="acceptedTerms"
					v-model="acceptedTerms"
					value="accepted"
					unchecked-value="not_accepted"
				>
					<i18n path="general.accept-terms1" tag="p">
						<a :href="$t('general.termsLink2')" target="_blank">
							{{ $t("general.accept-terms2") }}
						</a>
					</i18n>
				</b-form-checkbox>
				<b-form-invalid-feedback :state="acceptedTermsState">{{
					$t("general.must-accept-terms")
				}}</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4">
				<p>{{ $t("audio.upload-description") }}</p>
				<i18n path="general.problem" tag="p">
					<a href="mailto:dmn@rtv.rs" target="_blank" rel="noopener"
						>dmn@rtv.rs</a
					>
				</i18n>
			</b-form-group>

			<b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4">
				<b-button
					:click="check"
					type="submit"
					ref="submit"
					:disabled="busy"
					variant="primary"
					>{{ $t("audio.add-audio") }}</b-button
				>
				<div id="uploadInfo"></div>
			</b-form-group>
		</b-form>

		<b-overlay :show="busy" no-wrap @shown="confirmDialog" @hidden="onHidden">
			<template #overlay>
				<div class="row">
					<b-col
						cols="12"
						v-if="processing"
						class="text-center bg-white"
						style="width:400px; height: 300px; border: 2px solid #9ADCDA; border-radius: 8px;"
					>
						<div class="p-4 mt-5">
							<BIconCloudArrowUpFill font-scale="4" variant="primary" />
							<div class="mb-3">{{ $t("general.uploading") }}</div>
							<b-progress
								min="0"
								max="100"
								variant="primary"
								height="30px"
								class="mx-2 rounded-0"
							>
								<b-progress-bar :value="uploadProgress"
									>{{ uploadProgress.toFixed(2) }} %</b-progress-bar
								>
							</b-progress>
						</div>
					</b-col>
					<b-col
						cols="12"
						v-else
						ref="dialog"
						tabindex="-1"
						role="dialog"
						aria-modal="false"
						aria-labelledby="form-confirm-label"
						class="bg-white align-self-center"
						style="width:400px; height: 300px; border: 2px solid #9ADCDA; border-radius: 8px; text-align: center;"
					>
						<div class="p-4 mt-5">
							<div class="mb-3">
								<strong id="form-confirm-label">{{
									$t("general.are-you-sure")
								}}</strong>
							</div>
							<div>
								<b-button
									variant="outline-primary"
									class="mr-3"
									@click="onCancel"
									>{{ $t("general.no") }}</b-button
								>
								<b-button variant="primary" @click="onOK">{{
									$t("general.yes")
								}}</b-button>
							</div>
						</div>
					</b-col>
				</div>
			</template>
		</b-overlay>
	</div>
</template>

<script>
import BrowseButton from "@/components/util/BrowseButton.vue";
import {
	BProgress,
	BIconCloudArrowUpFill,
	BFormCheckbox,
	BProgressBar
} from "bootstrap-vue";
import editor from "@tinymce/tinymce-vue";
import TagInput from "@/components/admin/_utils_/TagInput.vue";

export default {
	name: "AudioForm",
	components: {
		BrowseButton,
		editor,
		BFormCheckbox,
		TagInput,
		BIconCloudArrowUpFill,
		BProgress,
		BProgressBar
	},
	props: {
		media: Object
	},
	data() {
		return {
			admin: {},
			tinyInitAudioDesc: this.tinyInit(350),
			tinyInitAudioEditDescEn: this.tinyInit(350),
			processing: false,
			uploading: false,
			busy: false,
			uploadProgress: 0,
			selectedCats: [],
			avaiableCategoryList: [],
			selectedLangs: [],
			avaiableLanguageList: [
				"Serbian",
				"English",
				"German",
				"Slovak",
				"Hungarian",
				"Bulgarian",
				"Romanian",
				"Ukranian",
				"Moldovan",
				"Rusyn",
				"Roma",
				"Other"
			],
			acceptedTerms: "not_accepted",
			/* State varijable  */
			titleState: null,
			mediaFileState: null,
			descriptionState: null,
			titleEnState: null,
			descriptionEnState: null,
			authorState: null,
			yearState: null,
			categoriesState: null,
			languagesState: null,
			acceptedTermsState: null
		};
	},
	async created() {
		this.admin = this.$root.user;
		this.displayTags();
	},

	methods: {
		onSubmit(e) {
			// console.log(this.media);
			if (this.validateForm()) {
				// console.log(this.media);
				this.busy = true;
				//console.log(this.processing)
				if (this.processing) {
					//console.log(this.media);
					this.media.createdBy = this.admin;
					this.media.categories = this.findRealTags(this.selectedCats);
					this.media.languages = this.selectedLangs.sort().join(",");
					const data = new FormData();
					data.append("mediaData", JSON.stringify(this.media));
					data.append("fullRes", document.getElementById("mediaFile").files[0]);
					const axiosConfig = {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: this.getAuthData().accessToken
						},
						onUploadProgress: function(progressEvent) {
							this.uploadProgress = Math.round(
								(progressEvent.loaded * 100) / progressEvent.total
							);
						}.bind(this)
					};

					this.$http.post("/cms/api/media/save", data, axiosConfig).then(r => {
							if (r.data) {
								this.$router.push({ name: "Dashboard" });
							}
						}).catch(error => {
							this.handleError(error);
						});
				}
        
			}
		},
		validateForm() {
			if (
				typeof this.media.title === "undefined" ||
				this.media.title.length === 0
			) {
				this.titleState = false;
				document
					.getElementById("title")
					.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				this.titleState = null;
			}

			if (
				typeof document.getElementById("mediaFile").files[0] === "undefined"
			) {
				this.mediaFileState = false;
				document.getElementById("audioFile").classList.add("is-invalid");
				document
					.getElementById("audioFile")
					.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				document.getElementById("audioFile").classList.remove("is-invalid");
				this.mediaFileState = null;
			}

			if (
				typeof this.media.description === "undefined" ||
				this.media.description.length === 0
			) {
				this.descriptionState = false;
				document
					.getElementById("description")
					.nextSibling.classList.add("is-invalid");
				document
					.getElementById("description")
					.parentNode.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				document
					.getElementById("description")
					.nextSibling.classList.remove("is-invalid");
				this.descriptionState = null;
			}

			if (
				typeof this.media.titleEn === "undefined" ||
				this.media.titleEn.length === 0
			) {
				this.titleEnState = false;
				document
					.getElementById("titleEn")
					.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				this.titleEnState = null;
			}

			if (
				typeof this.media.descriptionEn === "undefined" ||
				this.media.descriptionEn.length === 0
			) {
				this.descriptionEnState = false;
				document
					.getElementById("descriptionEn")
					.nextSibling.classList.add("is-invalid");
				document
					.getElementById("descriptionEn")
					.parentNode.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				document
					.getElementById("descriptionEn")
					.nextSibling.classList.remove("is-invalid");
				this.descriptionEnState = null;
			}

			if (
				typeof this.media.author === "undefined" ||
				this.media.author.length === 0
			) {
				this.authorState = false;
				document
					.getElementById("author")
					.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				this.authorState = null;
			}

			if (typeof this.media.year === "undefined") {
				this.yearState = false;
				document
					.getElementById("year")
					.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				// console.log(this.media.year);
				// console.log(this.media.year.replace(/[^0-9]+/g, ""));
				const year = this.media.year.toString().replace(/[^0-9]+/g, "");
				if (parseInt(year) === "NaN") {
					this.yearState = false;
					document
						.getElementById("year")
						.scrollIntoView({ block: "center", behavior: "smooth" });
					return false;
				}
				this.media.year = year;
				this.yearState = null;
			}

			if (this.selectedLangs === null || this.selectedLangs.length === 0) {
				this.languagesState = false;
				document
					.getElementById("langauges")
					.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				this.languagesState = null;
			}

			if (this.selectedCats.length === 0) {
				document
					.getElementById("categories")
					.scrollIntoView({ block: "center", behavior: "smooth" });
				this.categoriesState = false;
				return false;
			} else {
				this.categoriesState = null;
			}

			if (this.acceptedTerms === "not_accepted") {
				document
					.getElementById("acceptedTerms")
					.scrollIntoView({ block: "center", behavior: "smooth" });
				this.acceptedTermsState = false;
				return false;
			} else {
				this.acceptedTermsState = null;
			}

			return true;
		},
		check() {},
		confirmDialog() {
			this.$refs.dialog.focus();
		},
		onHidden() {
			this.$refs.submit.focus();
		},
		onOK() {
			this.processing = true;
			this.onSubmit();
		},
		onCancel() {
			this.processing = false;
			this.busy = false;
		},

		/* Traži u listi tagova one elemente koji se nalaze u listi izabranih kategorija */
		findRealTags(tagList) {
			return this.categoryTagObjectsList.filter(function(item) {
				for (var i = 0; i < item.names.length; i++) {
					if (tagList.includes(item.names[i].name)) {
						return item;
					}
				}
			});
		},

		extractTags(categoryList) {
			var adminLang = this.$root.lang.shortName;
			var myCategoriesSetring = [];
			categoryList.filter(function(item) {
				for (var i = 0; i < item.names.length; i++) {
					if (item.names[i].language.shortName === adminLang) {
						myCategoriesSetring.push(item.names[i].name);
					}
				}
			});
			return myCategoriesSetring;
		},

		async displayTags() {
			// Get all tags
			await this.$http
				.get("/cms/api/tag/all-published/" + this.admin.language.shortName, {
					headers: { Authorization: this.getAuthData().accessToken }
				})
				.then(r => {
					this.categoryTagObjectsList = r.data;
					if (r.data.length > 0) {
						var myOptions = [];
						for (var i = 0; i < r.data.length; i++) {
							var tag = r.data[i];
							if (tag.names.length > 0) {
								for (var j = 0; j < tag.names.length; j++) {
									var names = tag.names[j];
									if (names.language.shortName === this.$root.lang.shortName) {
										myOptions.push(names.name);
									}
								}
							}
						}
						// console.log("Moje opcije su!")
						// console.log(myOptions);
						this.avaiableCategoryList = myOptions;
						if (this.selectedCats.length > 0) {
							const myCategories = this.findRealTags(this.selectedCats);
							this.selectedCats = this.extractTags(myCategories);
						}
					}
				})
				.catch(e => {
					this.handleError(e);
				});
		}
	},

	computed: {
		_lang: function() {
			return this.$root.lang;
		}
	},

	watch: {
		selectedCats() {
			if (this.selectedCats.length > 0) {
				this.categoriesState = null;
			}
		},
		selectedLangs() {
			if (this.selectedLangs.length > 0) {
				this.languagesState = null;
			}
		},
		_lang: function() {
			// console.log("Promenjen jezik");
			this.displayTags();
		}
	}
};
</script>

<style></style>
